import get from 'lodash/get'

import type r4 from 'fhir/r4'

export const getItemPathByLinkId = (
  questionnaire: r4.Questionnaire,
  linkId: string,
  path = 'Questionnaire',
): string | undefined => {
  const item = get({ Questionnaire: questionnaire }, path) as
    | r4.QuestionnaireItem
    | undefined

  if (item?.linkId === linkId) {
    return path
  }

  for (
    let childIndex = 0;
    childIndex < (item?.item?.length ?? 0);
    childIndex += 1
  ) {
    const foundPath = getItemPathByLinkId(
      questionnaire,
      linkId,
      `${path}.item[${childIndex}]`,
    )

    if (foundPath) {
      return foundPath
    }
  }
}
